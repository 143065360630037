import React from 'react'
import "../styles/Banner.css"
function Banner() {
  return (
    <div>
        <header style={{ paddingLeft: 0 }}>
      <div
        className='p-5 text-center bg-image'
        style={{ backgroundImage: "url('https://c0.wallpaperflare.com/preview/285/1009/176/concery-wallpaper-festival-party.jpg')", height: 400 }}
      >
        <div className='mask'>
          <div>
            <div className='text-white'>
              <h1 className='mb-3 custom-h1'>Book Your Seat Now</h1>
              <br></br>
              <br></br>
              <h4 className='mb-2 custom-h4'>Refund And Rebooking Are Available Now For Any Cancelled Or Postpond Show. Get Your Queries Answers Instantly.Feel Free to Call Us</h4>
              <br></br>
              <br></br>
              <br></br>
              <a className='btn btn-outline-light btn-lg custom-h6' href="tel:+1 (866) 409-3890" role='button'>
               Call Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
    </div>
  )
}

export default Banner
