import React from 'react'
import "../styles/PreNavbar.css"
function PreNavbar() {
  return (
    <div className='PreNav'>
        <div>
            <h2>"We Are Secondary Marketplace, Not A Box Office Or Venue. Prices May Be Above Or Below Face Value."</h2>
        </div>
    </div>
  )
}

export default PreNavbar
