import React from 'react'
import "../styles/FirstContent.css"
function FirstContent() {
  return (
    <div className='FirstContent'>
        <br></br>
        <br></br>
        <br></br>
      <h1>Are you someone who lives in the moment?</h1>
      <br></br>
        <br></br>
        <br></br>
      <p>We are pleased to inform you that we offer show booking services for the most exceptional live events. The impact of COVID-19 has been felt in every aspect of life, infecting over 100 nations by March 2020 and prompting the World Health Organization to declare a global pandemic. As a result, severe travel restrictions, lockdowns, and “social distancing” measures were implemented. However, we have created a platform where you can easily purchase seat for your preferred live events, including concerts, sports, stand-ups, art, and theatre, ensuring a hassle-free experience. As we embark on a new beginning, you can rely on us to provide you with the best service possible.</p>
    </div>
  )
}

export default FirstContent
