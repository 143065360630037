import React from 'react'
import PreNavbar from '../Components/PreNavbar'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
function Privacy() {
  return (
    <div>
      <PreNavbar/>
      <Navbar/>
      <div className='a'
        style={{
          backgroundImage: "url('https://png.pngtree.com/thumb_back/fh260/background/20230325/pngtree-laser-spotlight-show-performance-concert-stage-decoration-pattern-image_2104666.jpg')",
          height: 200, // Decreased height
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <h1>Privacy Policy</h1>
      </div>
      <div className='last'>
        <h2>Privacy Policy</h2>
        <p>
       <a href='/'>Concertstips</a>  is concerned about privacy issues and wants you to be familiar with how we collect, use and disclose information. This Privacy Policy describes our practices in connection with information that we or our third-party service providers collect through certain of our owned or controlled websites, This Policy describes how we treat personal information we collect both online and offline. This includes on our websites. By providing Personal Information (as defined below) to us through the Properties, you agree to the terms and conditions of this Privacy Policy.

We collect information from and about you. Contact and billing information. When you create an account, buy a show or have a show transferred to you by a friend, we will collect your contact and billing information, such as your name, street address, zip code, email, phone number and credit card number. Information you post. We collect information you post in a public space on our website or on a third-party social media site. Demographic information. We might collect information like your age range, race, or gender, or information about show you like or products you buy. We might collect this as part of a survey, for example.

Accessibility Information. We may collect details of your health requirements if you have accessibility requirements when attending show. Other information. If you use our website or apps, we may collect information about the browser and device you’re using, your IP address, your location, the site you came from, the site you visit when you leave us, and how you used or didn’t use our site or app. We may collect this using technology such as GPS and Wi-Fi. We collect information in different ways. We collect information directly from you. For example, if you buy shows or register for a promotion. We also collect information if you post a comment on our websites or ask us a question. We collect information from you passively. We use tracking tools like browser cookies and web beacons. To learn more about these tools and to control them, please click here. We also collect information from our apps.
        </p>
      </div>
    <Footer/>
    </div>
  )
}

export default Privacy
