import React from 'react';
import PreNavbar from '../Components/PreNavbar';
import Navbar from '../Components/Navbar';
import "../styles/About.css";
import Footer from '../Components/Footer';

function About() {
  return (
    <div>
      <PreNavbar/>
      <Navbar/>
      <div className='a'
        style={{
          backgroundImage: "url('https://c0.wallpaperflare.com/preview/285/1009/176/concery-wallpaper-festival-party.jpg')",
          height: 200, // Decreased height
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <h1>Who we are?</h1>
      </div>
      <div className='b'>
        <div>
          <h1>About us</h1>
          <p>We are a live entertainment platform and our vision is to place our fans first. We’ve organized our business in a way that the fans, artists, and teams are provided with all the resources and amenities they require for a successful shows.

          With our show-rating engine and our shows experience platform, we make it simple for fans to gather all the information about their favorite showss and allow them to attend them with as ease as possible. We are the finest rated and most dependable show provider thanks to these features and more.</p>
        </div>
        <div>
          <img src="https://cc-prod.scene7.com/is/image/CCProdAuthor/concert-photography_P1_900x420?$pjpeg$&jpegSize=200&wid=900" alt="Concert" />
          <img src="https://images.pexels.com/photos/167636/pexels-photo-167636.jpeg?cs=srgb&dl=pexels-thibault-trillet-44912-167636.jpg&fm=jpg" alt="Concert" />
          <img src="https://media.istockphoto.com/id/1357248261/photo/crowd-of-people-dancing-in-a-concert.webp?b=1&s=170667a&w=0&k=20&c=SiO5dLJA8Gh7Wymv2Vsp_fdjgbrvxqdnQzCP3ZDHK60=" alt="Concert" />
   
        </div>
        <div>
          <h2>Extraordinary Experiences</h2>
          <p>With the help of our enterprise software, teams, venues, and performances can effectively expand their companies while providing all fans with the first-rate service they deserve. Since our inception, we’ve had the honor of working with some of the most recognizable personalities in sports and entertainment.</p>
        </div>
        <div>
          <h2>Our Core Values</h2>
          <p>We wanted to make sure that all the services we provide are secure, trustworthy, easy to use, and safe. We’ve made sure that our website is simple to use and navigate since we know that our clients want a quick and simple approach to ordering shows. Our user-friendly platform has all the functionality you require to quickly and simply purchase shows for your shows</p>
        </div>
      </div>

      <div className='dis'>
        <p>
        Disclaimer: We are a secondary market place. Not a box office or a venue. show price may vary as per the value, organizers and availability of shows. If the show is cancelled at our end or if the show gets cancelled by the organizer, then the customer is entitled for entire compensation of show amount. If customer cancels the show at his end and intimate us timely then after following a certain procedure customer can get his refund of cancelled show.

        </p>

      </div>
      <div className='form-container'>
  <h1>We're Ready, Let's Talk.</h1>

  <div className="form-content">
    <form className="styled-form">
      <div className="form-group">
        <label for="exampleInputEmail1">Enter Name</label>
        <input type="name" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter your name" />
        
      </div>
      <div className="form-group">
        <label for="exampleInputPassword1">Enter Your Email</label>
        <input type="email" className="form-control" id="exampleInputPassword1" placeholder="Email" />
      </div>
      <button type="submit" className="btn btn-primary">Send</button>
    </form>

    <div className="contact-info">
      <h1>Contact Info</h1>
      <p>Email Us: info@concertstips.shop</p>
      <p>Call Us: +1 (866) 409-3890</p>
    </div>
  </div>
</div>
        <Footer/>
    </div>
  );
}

export default About;
