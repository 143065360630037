import React from 'react'
import PreNavbar from '../Components/PreNavbar'
import Navbar from '../Components/Navbar'
import "../styles/Coupon.css"
import Footer from '../Components/Footer'
function Coupon() {
  return (
    <div>
      <PreNavbar/>
      <Navbar/>
      <div className='a'
        style={{
          backgroundImage: "url('https://images.pexels.com/photos/167636/pexels-photo-167636.jpeg?cs=srgb&dl=pexels-thibault-trillet-44912-167636.jpg&fm=jpg')",
          height: 200, // Decreased height
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <h2>Concertstips coupon Codes 2024</h2>
      </div>
      <div className='cou'> 
        <ul>
          <li>Up to 50% Off
Concertstips Special Offer Code: Up to 50% off select shows
<a href='/'><button>Get Coupon</button></a>
</li>
        <li>
        Bogo Free
2-for-1 Deal: Buy one, get one free on select shows with this  promo code
<a href='/'><button>Get Coupon</button></a>
        </li>
        <li>
        50% Off
Latest Concertstips promo codes and deals for up to 50% off
<a href='/'><button>Get Coupon</button></a>
        </li>
        <li>
        30% Off
AARP Discount: Up to 30% off select concerts and shows
<a href='/'><button>Get Coupon</button></a>
        </li>
        <li>
        50% Off
Winter Specials: Up to 50% off local concerts, shows, sporting events, and more
<a href='/'><button>Get Coupon</button></a>
        </li>
        <li>
        50% Off
Hot Deals: Las Vegas shows and concerts for 10-50% off
<a href='/'><button>Get Coupon</button></a>
        </li>
        </ul>
        
      </div>
      <Footer/>
    </div>
  )
}

export default Coupon
