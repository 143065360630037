import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Pop from './Components/Pop';
import Coupon from './Pages/Coupon';
import Privacy from './Pages/Privacy';
import ScrollToTop from './ScrollToTop';
import CallButton from './Components/Callbutton';
import PageViews from "./PageViews";



function App() {
  const [showPop, setShowPop] = useState(true);

  const handleClosePop = () => {
    setShowPop(false);
  };

  useEffect(() => {
    document.body.classList.add('loaded');
    return () => {
      document.body.classList.remove('loaded');
    };
  }, []);

  return (
    <div className="App">

      <BrowserRouter>
      <ScrollToTop/>
        <PageViews onClosePop={setShowPop} />
       {showPop && <Pop onClose={handleClosePop} />}
        <Routes>
          <Route index element={<Home />} />
          <Route path="/" element={<Home/>}/>
          <Route path='/about' element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/coupon" element={<Coupon/>}/>
          <Route path="/privacy" element={<Privacy/>}/>
        </Routes>
        <CallButton/>
      </BrowserRouter>
    </div>
  );
}

export default App;
