import React from 'react'
import "../styles/Artist.css"
function Artist() {
  return (
    <div className='Artist'>
      <br></br>
        <br></br>
        <br></br>
      <h1>Our Top Artist</h1>
      <br></br>
      <br></br>


      
<div className="row">
  <div className="col-lg-4 col-md-12 mb-4 mb-lg-0">
    <img
      src="https://cdn.pixabay.com/photo/2022/03/10/20/28/rock-band-7060689_640.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Boat on Calm Water"
    />

    <img
      src="https://w0.peakpx.com/wallpaper/544/109/HD-wallpaper-justin-bieber-black-concert-justin-lights-red-stage.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Wintry Mountain Landscape"
    />
  </div>

  <div className="col-lg-4 mb-4 mb-lg-0">
    <img
      src="https://wallpapers.com/images/hd/lil-wayne-trunks-qp0rs7wf7t191smx.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Mountains in the Clouds"
    />

    <img
      src="https://c4.wallpaperflare.com/wallpaper/302/712/932/katy-perry-makeup-painted-nails-purple-hair-wallpaper-preview.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Boat on Calm Water"
    />
  </div>

  <div className="col-lg-4 mb-4 mb-lg-0">
    <img
      src="https://e0.pxfuel.com/wallpapers/76/35/desktop-wallpaper-zoya-blodos-world-s-famous-singers-american-hot-and-actress-thumbnail.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Waves at Sea"
    />

    <img
      src="https://e0.pxfuel.com/wallpapers/346/322/desktop-wallpaper-zayn-malik-celebrities-male-celebrities-singer-boys-for-iphone-6-7-8.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Yosemite National Park"
    />
  </div>
</div>
    <div><a className='btn btn-outline-light btn-lg custom-h6' href='tel:+1 (866) 409-3890' role='button'>
               Book Your Ticket Now
              </a></div>
    </div>
  )
}

export default Artist
