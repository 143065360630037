import React from 'react'
import "../styles/Footer.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter, faGoogle, faInstagram } from '@fortawesome/free-brands-svg-icons';
function Footer() {
  return (
    <div className='Footer'>
      <p><b>Disclaimer:</b><span>We are a secondary market place. Not a box office or a venue. show price may vary as per the value, organizers and availability of shows. If the show is cancelled at our end or if the show gets cancelled by the organizer, then the customer is entitled for entire compensation of show amount. If customer cancels the show at his end and intimate us timely then after following a certain procedure customer can get his refund of cancelled show.</span></p>
      <br></br>
      <p><b>Disclosure: </b><span>This is not the official website of shows & Concerts shows booking In USA, it belongs to authorised channel partner for information purpose only. All rights for logo & images are reserved to developer. Thank you for visiting our website. This disclaimer (“Disclaimer”) is applicable to this website and all microsites and websites owned by us. By using or accessing this website you agree with the Disclaimer without any qualification or limitation.</span></p>
      <div className="container my-5">
      <footer className="text-center text-lg-start text-white" style={{ backgroundColor: 'black' }}>
        <div className="container p-4 pb-0">
          <section>
            <div className="row">
              <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                <h6 className="text-uppercase mb-4 font-weight-bold">Company name</h6>
                <h4>
                BookYourSheat
                </h4>
              </div>

              <hr className="w-100 clearfix d-md-none" />

              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
                <h6 className="text-uppercase mb-4 font-weight-bold">Events</h6>
                <p>
                  <a href="/" className="text-white">Concert</a>
                </p>
                <p>
                  <a href="/" className="text-white">Sports</a>
                </p>
                <p>
                  <a href="/" className="text-white">Art & Theater</a>
                </p>
              </div>

              <hr className="w-100 clearfix d-md-none" />

              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
                <h6 className="text-uppercase mb-4 font-weight-bold">Useful links</h6>
                <p>
                  <a href="/" className="text-white">Home</a>
                </p>
                <p>
                  <a href="/" className="text-white">Need help?</a>
                </p>
                <p>
                  <a href="/" className="text-white">Privacy Policy</a>
                </p>
                <p>
                  <a href="/" className="text-white">Terms & Conditions</a>
                </p>
              </div>

              <hr className="w-100 clearfix d-md-none" />

              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
                <h6 className="text-uppercase mb-4 font-weight-bold">Contact Us</h6>
                <p><FontAwesomeIcon icon={faHome} /> Street Road, US</p>
                <p><FontAwesomeIcon icon={faEnvelope} /> info@concertstips.shop</p>
                <p><FontAwesomeIcon icon={faPhone} />+1 (866) 409-3890</p>
               
              </div>
            </div>
          </section>

          <hr className="my-3" />

          <section className="p-3 pt-0">
            <div className="row d-flex align-items-center">
              <div className="col-md-7 col-lg-8 text-center text-md-start">
                <div className="p-3">
                  © 2024 Copyright:
                  <a className="text-white" href="/"> concertstips.shop</a>
                </div>
              </div>

              <div className="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end">
                <a className="btn btn-outline-light btn-floating m-1" href="/" role="button">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a className="btn btn-outline-light btn-floating m-1" href="/" role="button">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a className="btn btn-outline-light btn-floating m-1" href="/" role="button">
                  <FontAwesomeIcon icon={faGoogle} />
                </a>
                <a className="btn btn-outline-light btn-floating m-1" href="/" role="button">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </div>
            </div>
          </section>
        </div>
      </footer>
    </div>
    </div>
  )
}

export default Footer
