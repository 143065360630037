
import React from 'react'
import PreNavbar from '../Components/PreNavbar'
import Navbar from '../Components/Navbar'
import "../styles/Contact.css"
import Footer from '../Components/Footer'
function Contact() {
  return (
    <div>
      <PreNavbar/>
      <Navbar/>
      <div className='a'
        style={{
          backgroundImage: "url('https://images5.alphacoders.com/349/thumb-1920-349660.jpg')",
          height: 200, // Decreased height
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <h2>Contact Us</h2>
      </div>
      <div className='details'>
        <p>
        Geturrefunds is a worldwide online marketplace for showss to live performances of music, sports, and other showss. We make it easy for fans to find out everything there is to know about their favorite showss and enable them to attend them as easily as possible with our state-of-the-art shows-matching algorithms and technologies. Thanks to these qualities and more, we have the best reputation in the market.

 

We’ve always prioritized our fans. That entails no inflated shows pricing or spam on topics you don’t care about. Everyone should be able to enjoy live music and showss, and we make a lot of effort to empower our community and cut out the BS along the way. Positive vibes only from this place.
        </p>

      </div>
      <div className='form-container'>
  <h1>Contact Info</h1>

  <div className="form-content">
    <form className="styled-form">
      <div className="form-group">
        <label for="exampleInputEmail1">Enter Name</label>
        <input type="name" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter your name" />
        
      </div>
      <div className="form-group">
        <label for="exampleInputPassword1">Enter Your Email</label>
        <input type="email" className="form-control" id="exampleInputPassword1" placeholder="Email" />
      </div>
      <button type="submit" className="btn btn-primary">Send</button>
    </form>

    <div className="contact-info">
      <h1>Contact Info</h1>
      <p>Email Us: info@concertstips.shop</p>
      <p>Call Us: +1 (866) 409-3890</p>
      <p>You can feel free to reach out to us for any inquiries. If you’re having difficulty searching for artists or are unable to find the best showss in your city or just anything, we are here to help.</p>
    </div>
  </div>
</div>
<Footer/>

    </div>
  )
}

export default Contact
