import React from 'react'
import "../styles/FourthContent.css"
function FourthContent() {
  return (
    <div className='FourthContent'>
      <br></br>
        <br></br>
        <br></br>
      <h1>Have peace of mind with our secure payment process</h1>
      <br></br>
        
      <p>With the showing industry still evolving, we wanted to ensure that all the showing services we offer are safe, secure, reliable, and easy to use. We know that our customers are looking for a quick and easy way to book shows, so we’ve made sure that our website is easy to use and navigate. Our easy-to-use platform offers all the essential features that you need to easily book seat for your show. With our secure payment system, you can have peace of mind</p>
    </div>
  )
}

export default FourthContent
