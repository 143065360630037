import React from 'react'

function ThirdContent() {
  return (
    <div className='ThirdContent'>
       <br></br>
        <br></br>
        <br></br>
      <h1>What drives our business model</h1>
      <br></br>
        <br></br>
        <br></br>
      <p>The most important part of our business is you! Yes, you-the fans. We, as a live entertainment platform have reimagined showing by giving priority to the teams, fans, and venues.  By enabling a fresh, open entertainment market, we make it possible for teams, venues, and events to quickly reach their target audiences as well as for fans to simply enjoy experiences.</p>
      <br></br>
        <br></br>
        <p>We know when you go to an event, there’s nothing like it and you want more. Hence, we have been committed to providing you with shows to your favorite events easily and as frequently as possible. We are working around the clock to enhance events discovery easy for you and make your time fun and delightful at the events. </p>
    </div>
  )
}

export default ThirdContent
