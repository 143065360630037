import React, { useState } from "react";
import "../styles/Navbar.css";
import { Link } from "react-router-dom";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="Navbar">
      <button className="navbar-toggler" onClick={toggleMenu}>
        &#9776;
      </button>
      <ul className={`nav justify-content-center ${isOpen ? "open" : ""}`}>
        <li className="nav-item">
          <Link to="/" className="nav-link">Home</Link>
        </li>
        <li className="nav-item">
          <Link to="/about" className="nav-link">About</Link>
        </li>
        <li className="nav-item">
          <Link to="/coupon" className="nav-link">Concertstips Coupon Code</Link>
        </li>
        <li className="nav-item">
          <Link to="/contact" className="nav-link">Contact Us</Link>
        </li>
        <li className="nav-item">
          <Link to="/privacy" className="nav-link">Privacy Policy</Link>
        </li>
      </ul>
    </div>
  );
}

export default Navbar;
