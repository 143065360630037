import  { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageViews = ({ onClosePop }) => {
  const location = useLocation();

  useEffect(() => {
    // Show the pop-up on route changes
    onClosePop(true);
    window.scrollTo(0, 0); // Ensure the scroll position is reset to top
  }, [location.pathname, onClosePop]);

  return null;
};

export default PageViews;
