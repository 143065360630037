import React from 'react'
import "../styles/BookingForm.css"
function BookingForm() {
  return (
    <div className='BookingForm'>
      
    </div>
  )
}

export default BookingForm
