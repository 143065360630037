import React from 'react'
import "../styles/Events.css"

function Events() {
  return (
    <div className='Events'>
      
      <div >
        <div className="card">
            <img src="https://images.pexels.com/photos/1105666/pexels-photo-1105666.jpeg" className="card-img-top" alt="..."/>
            <div className="card-body">
                <h1 className="card-title">Concert</h1>
            </div>
            <ul className="list-group list-group-flush">
                <li className="list-group-item"><b>Joe Russo’s Almost Dead Tickets
                </b><br></br>The Pageant, Saint Louis, MO</li>
                <li className="list-group-item"><b>Elton John</b><br/>Madison Square Garden New York, NY</li>
                <li className="list-group-item"><b>Jenny Lewis</b><br/>Madison Square Garden, New York, NY</li>
                <li className="list-group-item"><b>Chicago Tickets</b><br/>Pechanga Entertainment Center, Temecula, CA</li>
                <li className="list-group-item"><b>Usher – The Vegas Residency</b><br/>The Colosseum at Caesars Palace – Las Vegas, NV</li>
                <li className="list-group-item"><b>Usher – The Vegas Residency</b><br/>The Colosseum at Caesars Palace – Las Vegas, NV</li>
                
            </ul>
            <button><a className="custom-link" href="/">view more</a></button>
            
        </div>
      </div>

      <div>
        <div className="card">
            <img src="https://media.istockphoto.com/id/637332860/photo/multi-sports-proud-players-collage-on-grand-arena.webp?b=1&s=170667a&w=0&k=20&c=PXalaqxJChrb-n7unWeqI5PszHlj2xZAD3UPUGk8bR4=" className="card-img-top" alt="..."/>
            <div className="card-body">
                <h1 className="card-title">Sports</h1>
            </div>
            <ul className="list-group list-group-flush">
                <li className="list-group-item"><b>Pittsburgh Improv – Pittsburgh, PA</b><br/>Bojangles Coliseum MO</li>
                <li className="list-group-item"><b>Legends Classic</b><br/>Barclays Center, Brooklyn, NY</li>
                <li className="list-group-item"><b>New York Giants v New England</b><br/>Patriots MetLife Stadium East Rutherford, NJ</li>
                <li className="list-group-item"><b>New York Yankees v. Baltimore Orioles</b><br/>Yankee Stadium Bronx, NY</li>
                <li className="list-group-item"><b>Monster Jam Tickets</b><br/>Dunkin Donuts Center, Providence, RI Palace – Las Vegas, NV</li>
                <li className="list-group-item"><b>Belmont Stakes Tickets</b><br/>Belmont Park, Elmont, NY Palace – Las Vegas, NV</li>
            </ul>
            <button><a className="custom-link" href="/">view more</a></button>
        </div>
      </div>

      <div>
        <div className="card">
            <img src="https://c4.wallpaperflare.com/wallpaper/60/106/467/theatre-art-illustration-wallpaper-preview.jpg" className="card-img-top" alt="..."/>
            <div className="card-body">
                <h1 className="card-title">Art & Theater</h1>
            </div>
            <ul className="list-group list-group-flush">
                <li className="list-group-item"><b>SIX (New York, NY)</b><br/>Brooks Atkinson Theatre, New York, NY MO</li>
                <li className="list-group-item"><b>ubHub – Where Fans Buy & Sell Tickets Frozen The Musical Minneapolis Tickets</b><br/>Orpheum Theatre Minneapolis, Minneapolis, MN</li>
                <li className="list-group-item"><b>ts untitled: Dave Chappelle Documentary Tickets</b><br/>Hollywood Bowl, Hollywood, CA</li>
                <li className="list-group-item"><b>Lion King</b><br/>Minskoff Theatre, New York, NY Palace – Las Vegas, NV</li>
                <li className="list-group-item"><b>Pretty Woman Milwaukee Tickets</b><br/>Uihlein Hall at Marcus Center for the Performing Arts, Milwaukee, WI, US Palace – Las Vegas, NV</li>
            </ul>
            <button><a className="custom-link" href="/">view more</a></button>
        </div>
      </div>
      
    </div>
  )
}

export default Events
