import React from 'react';
import Modal from 'react-bootstrap/Modal';
import "../styles/Pop.css"

function Pop({ onClose }) {
  return (
    <Modal show={true} onHide={onClose} centered keyboard={false} backdrop="static">
      <Modal.Header className='modal-header '>
        <button className="close-btn" onClick={onClose}>
            X
        </button>
      </Modal.Header>
      <Modal.Body className="modal-body-custom">
        <img 
          src='https://images.unsplash.com/photo-1459749411175-04bf5292ceea?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGNvbmNlcnR8ZW58MHx8MHx8fDA%3D' 
          alt='Concert' 
          className='modal-image'
        />
        <div className="modal-text">
          <h2>Refund And Rebooking Are Available Now For Any Cancelled Or Postponed Shows</h2>
          <h3>Get Your Queries Answers Instantly Feel Free to Call Us</h3>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <a className='btn btn-outline-light btn-dark custom-h6' href="tel:+1 (866) 409-3890" role='button'>
          Call Now
        </a>
      </Modal.Footer>
    </Modal>
  );
}

export default Pop;
