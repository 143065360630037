import React from 'react'
import "../styles/SecondContent.css"
function SecondContent() {
  return (
    <div className='SecondContent'>
        <h1>UPCOMING Content</h1>
    </div>
  )
}

export default SecondContent
