import React from 'react'
import PreNavbar from '../Components/PreNavbar';
import  { useEffect } from 'react';
import Navbar from '../Components/Navbar';
import Banner from '../Components/Banner';
import FirstContent from '../Components/FirstContent';
import SecondContent from '../Components/SecondContent';
import Events from '../Components/Events';
import ThirdContent from '../Components/ThirdContent';
import BookingForm from '../Components/BookingForm';
import FourthContent from '../Components/FourthContent';
import Artist from '../Components/Artist';
import Footer from '../Components/Footer';
import "../styles/Home.css"

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <PreNavbar/>
      <Navbar/>
      <Banner/>
      <FirstContent/>
      <SecondContent/>
      <Events/>
      <ThirdContent/>
      <BookingForm/>
      <FourthContent/>
      <Artist/>
      <Footer/>
      

    </div>
  )
}

export default Home
